<template>
  <div class="mx-auto mb-5" style="max-width: 1200px; padding-top: 30px">
    <div class="d-flex w-100">
      <div>
        <h1 class="help_title">{{$t("help_earn_title")}}</h1>
       <div v-html="$t('help_earn_con_new')"></div>
      </div>
    </div>
  </div>
</template>
