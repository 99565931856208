<template>
  <div class="mt-3" style="background: #00bc86; border-radius: 16px">
    <div class="div-header">
      <span>{{$t("text_userid")}}</span>
      <span>{{$t("home_text3")}}</span>
      <span>{{$t("home_text4")}}</span>
    </div>
    <div
      class="div-container"
      style="
        height: auto;
        overflow-y: hidden;
        border-bottom: 15px solid #00bc86;
        border-radius: 16px;
      "
    >
      <div class="content1" v-for="l in tableData" :key="l">
        <span class="text-start">{{ l.userid }}</span>
        <span>{{ l.bet }}</span>
        <span>{{ l.win }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      tableData: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 500 + 1000)),
          win: this.change_val(Math.ceil(Math.random() * 1000 + 1500)),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 200 + 800)),
          win: this.change_val(Math.ceil(Math.random() * 200 + 1000)),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 100 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 100 + 800)),
        },
      ],
    };
  },
  methods: {
    change_val(val){
      if (val / 1000000000 > 1)
          return val / 1000000000 + "B";
        else if (val / 1000000 > 1)
          return val / 1000000 + "M";
        else if (val / 1000 > 1)
          return val / 1000 + "K";
        else return val;
    }
  },
  mounted() {},
  created() {},
  components: {},
};
</script>
