<template>
  <div
    class="wc-PageView_ContentContainer wc-LegacyPage_ContentContainerBankBalance wc-ModulePageChangePageData_ContentContainer mt-4"
  >
    <div>
      <div>
        <section data-section="sba-balances">
          <div class="balanceLayout">
            <div class="sba-container">
              <div class="sba-balance sports-balance">
                <div data-section="SbaSports-balance-total">
                  <div class="balance-row sports-header">
                    <div class="balance-column withdraw-header">
                      <div>{{ $t("main_acc") }}</div>
                    </div>

                    <div class="balance-column withdraw-value">
                      <div>{{ balance }}</div>
                    </div>
                  </div>

                  <div class="buttons-row">
                    <div
                      class="button-links btn-deposit rounded-5"
                      @click="this.$emit('selectid', 'deposit')"
                    >
                      <div class="fn-btn-deposit">{{ $t("deposit") }}</div>
                    </div>

                    <div
                      class="button-links btn-withdraw rounded-5"
                      @click="this.$emit('selectid', 'withdraw')"
                    >
                      <div class="fn-btn-withdrawal">{{ $t("withdrawl") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "Home",
  computed: {},
  data() {
    return {
      balance: 0.0,
    };
  },
  methods: {
    getBalance: function () {
      let param = {
        action: "game_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param);
      param = {
        action: "balance",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (parseFloat(res["balance"]) / 1000000000 > 1)
          this.balance =
            (parseFloat(res["balance"]) / 1000000000).toFixed(2) + "B";
        else if (parseFloat(res["balance"]) / 1000000 > 1)
          this.balance =
            (parseFloat(res["balance"]) / 1000000).toFixed(2) + "M";
        else if (parseFloat(res["balance"]) / 1000 > 1)
          this.balance = (parseFloat(res["balance"]) / 1000).toFixed(2) + "K";
        else this.balance = parseFloat(res["balance"]).toFixed(2);
      });
    },
  },
  created() {
    this.getBalance();
  },
};
</script>
