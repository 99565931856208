<template>
  <div
    class="d-flex align-items-center"
    v-if="code != null"
    style="z-index: 99; height: 8vh; right: 20px"
  >
    <img
      src="@/assets/img/main/diamond.png"
      class="col-4 balance_img me-2"
      style="width: 30px; height: 30px; cursor: pointer"
      @click="$router.push({ path: '/ME/wallet', query: { id: 'deposit' } })"
    /><span class="fs_16 fw-bolder">{{ balance }}</span>
    <img
      src="@/assets/img/main/one_click_recycling.png"
      class="ms-2"
      style="width: 30px; height: 30px; cursor: pointer"
      @click="allgameToLobby"
    />

    <!-- <lang class="ms-2" v-if="this.$route.name == 'VIP'"></lang> -->
  </div>
</template>
<script>
import Cookies from "js-cookie";
// import Lang from "./Home/language.vue";
export default {
  name: "balance",
  data() {
    return {
      code: Cookies.get("code"),
      balance: 0,
    };
  },
  components: {
    // Lang,
  },
  computed: {},
  methods: {
    allgameToLobby: function () {
      let param = {
        action: "allgame_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.$MSG.API_popmsg(res["balance"], "allgameTolobby", "success");
      });
    },
    getBalance: function () {
      let param = {
        action: "balance",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (parseFloat(res["balance"]) / 1000000000 > 1)
          this.balance =
            (parseFloat(res["balance"]) / 1000000000).toFixed(2) + "B";
        else if (parseFloat(res["balance"]) / 1000000 > 1)
          this.balance =
            (parseFloat(res["balance"]) / 1000000).toFixed(2) + "M";
        else if (parseFloat(res["balance"]) / 1000 > 1)
          this.balance = (parseFloat(res["balance"]) / 1000).toFixed(2) + "K";
        else this.balance = parseFloat(res["balance"]).toFixed(2);
      });
    },
  },
  created() {
    if (this.code != null) {
      this.getBalance();
    }
  },
  watch: {},
};
</script>
