<template>
  <div>
    <rankt1 />
    <ranktnew class="d-none" />

    <div class="mt-3" style="background: #00bc86; border-radius: 16px">
      <div class="div-header">
        <span>{{ $t("rank_text1") }}</span>
        <span>{{ $t("home_text4") }}</span>
        <span>{{ $t("betAmountTitle") }}</span>
        <span>{{ $t("rank_text2") }}</span>
      </div>
      <div
        class="div-container"
        style="
          height: 45vh;
          overflow-y: hidden;
          border-bottom: 15px solid #00bc86;
          border-radius: 16px;
        "
      >
        <div class="content1" v-for="l in tableData" :key="l">
          <span class="text-start"
            ><b>{{ gamename_arr[l.name].toUpperCase() }}</b></span
          >
          <span>{{ l.userid }}</span>
          <span
            ><b>{{ l.bet }}</b></span
          >
          <span>{{ Math.ceil(l.bet * 1.2) + l.win }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rankt1 from "./rank_t1.vue";
import ranktnew from "./rank_new.vue";
export default {
  props: {},
  data() {
    return {
      gamename_arr: [
        "crash",
        "double",
        "dice",
        "keno",
        "mine",
        "crypto",
        "hilo",
        "coin",
        "tower",
      ],
      tableData: [
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
          win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
        },
      ],
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var insert_data = [
          {
            name: Math.floor(Math.random() * 9),
            userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
            bet: this.change_val(Math.ceil(Math.random() * 1000 + 500)),
            win: this.change_val(Math.ceil(Math.random() * 299 + 100)),
          },
        ];
        this.tableData.push(insert_data[0]);
        this.tableData.shift();
      }, 1000);
    },
    change_val(val){
      if (val / 1000000000 > 1)
          return val / 1000000000 + "B";
        else if (val / 1000000 > 1)
          return val / 1000000 + "M";
        else if (val / 1000 > 1)
          return val / 1000 + "K";
        else return val;
    }
  },
  mounted() {},
  created() {
    this.scroll();
  },
  components: {
    rankt1,
    ranktnew,
  },
};
</script>
