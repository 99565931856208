<template>
  <div>
    <div class="draw_float_big_bg"></div>
    <div class="draw_float_big_main">
      <div class="draw_float_big">
        <div
          class="closeBtn"
          @click="this.$emit('close_wheel_main', false)"
        ></div>
        <div class="lucky_title">
          <img src="@/assets/img/roulette/bonus-text-bg.b0ace51.png" alt="" />
        </div>
        <div class="wheel">
          <div class="wheel__main" id="wheel__main">
            <img src="@/assets/img/roulette/wheel.png" alt="" />
          </div>
          <div class="wheel__bord">
            <img
              src="@/assets/img/roulette/main-sr-wheel-item-2.6961b1b.png"
              alt=""
            />
          </div>
        </div>
        <div class="lucky_money lucky_money1">
          <img src="@/assets/img/roulette/img-coins-left.88ef3d8.png" alt="" />
        </div>
        <div class="lucky_money lucky_money2">
          <img src="@/assets/img/roulette/img-coins-right.b416b56.png" alt="" />
        </div>
        <div class="lucky_plat">
          <img src="@/assets/img/roulette/bg.bf9b33f.png" alt="" />
          <input
            type="button"
            value="GO"
            @click="click_go()"
            class="btn"
            :disabled="flag_btn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      flag_btn: false,
    };
  },
  methods: {
    click_go() {
      if (Cookies.get("code") == null || Cookies.get("code") == "") {
        this.$router.push("Login");
      } else {
        document.getElementById("wheel__main").classList.add("wheel__main_ani");
        this.flag_btn = true;

        let param = {
          action: "wheel",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var seq = 10 - res.seq;
          var deg_index_from = (360 / 9) * (seq - 1) + 15;
          var deg_index_to = (360 / 9) * seq - 10;

          var result =
            1080 +
            Math.floor(
              Math.random() * (deg_index_to + 1 - deg_index_from) +
                deg_index_from
            );

          const runkeyframes =
            ` @keyframes spin_wheel{
                                                        0%{
                                                             transform: rotate(0deg);
                                                        }
                                                        100%{
                                                            transform: rotate(` +
            result +
            `deg);
                                                        }
                                                    }`;

          // 创建style标签
          const style = document.createElement("style");
          // 设置style属性
          style.type = "text/css";
          // 将 keyframes样式写入style内
          style.innerHTML = runkeyframes;
          // 将style样式存放到head标签
          document.getElementsByTagName("head")[0].appendChild(style);

          setTimeout(() => {
            document
              .getElementById("wheel__main")
              .classList.remove("wheel__main_ani");
            this.flag_btn = false;
            document.getElementsByTagName("head")[0].removeChild(style);
            this.$MSG.API_popmsg(
              this.$t("text_get_wheel") + res.name,
              "",
              "success"
            );
          }, 5000);
        });
      }
    },
  },
};
</script>
